var SeenecoFramework;
(function (SeenecoFramework) {
	var FormatUtils = (function () {
		function FormatUtils() {}
		/**
		 * Formats given numeric value to money format with currency symbol
		 */
		FormatUtils.toMoneyWithCurrency = function (money, precision, currencyUnit) {
			return this.toMoney(money, precision) + ' ' + (currencyUnit || defaultTemplate.CurrencyUnit);
		};
		/**
		 * Formats given numeric value to money format with sign
		 */
		FormatUtils.toMoneyWithSign = function (money, precision) {
			var sign = money
				? money < 0
					? '<i class="icon-minus text-error"></i> '
					: '<i class="icon-plus text-success"></i> '
				: '';
			if (money < 0) money = -money;
			return sign + this.toMoney(money, precision);
		};
		/**
		 * Formats given numeric value to money format with sign
		 */
		FormatUtils.toMoneyWithSignAndCurrency = function (money, precision, currencyUnit) {
			return this.toMoneyWithSign(money, precision) + ' ' + (currencyUnit || defaultTemplate.CurrencyUnit);
		};
		/**
		 * Formats given numeric value to money format
		 */
		FormatUtils.toMoney = function (money, precision) {
			money = stringToFloat(String(money));
			precision = precision || 0;
			return numberFormat(money, precision, ',', ' ');
		};
		/**
		 * Formats given numeric value to percent format
		 */
		FormatUtils.toPercent = function (value, precision) {
			return this.toMoney(value, precision) + '%';
		};
		return FormatUtils;
	})();
	SeenecoFramework.FormatUtils = FormatUtils;
	var StringUtils = (function () {
		function StringUtils() {}
		/**
		 * Converts string value of text input to float value
		 */
		StringUtils.toFloat = function (textValue) {
			return stringToFloat(textValue);
		};
		/**
		 * Trims string to the passed length
		 */
		StringUtils.trimByLength = function (str, length) {
			return trimStringByLength(str, length);
		};
		/**
		 * Replace new lines with breaks
		 */
		StringUtils.replaceNewLinesWithBreaks = function (str) {
			return str.replace(/(?:\r\n|\r|\n)/g, '<br />');
		};
		StringUtils.replaceBreaksWithNewLines = function (str) {
			return str.replace(/<br \/>/g, '\r\n');
		};
		/**
		 * Replace counterparty abbreviations
		 */
		StringUtils.replaceCounterpartyAbbreviation = function (str) {
			return replaceCounterpartyAbbreviation(str);
		};
		/**
		 * Add title to text and replace origin text by replacer
		 */
		StringUtils.addWrapWithTitle = function (text, replacer) {
			return addWrapWithTitle(text, replacer);
		};
		return StringUtils;
	})();
	SeenecoFramework.StringUtils = StringUtils;
	var ChartUtils = (function () {
		function ChartUtils() {}
		/**
		 * Returns HighCharts gradient for given color
		 */
		ChartUtils.getLinearGradientByColor = function (color) {
			return getLinearGradientByColor(color);
		};
		return ChartUtils;
	})();
	SeenecoFramework.ChartUtils = ChartUtils;
	var CookieUtils = (function () {
		function CookieUtils() {}
		/**
		 * Sets cookie
		 */
		CookieUtils.setCookie = function (name, value, options) {
			return setCookie(name, value, options);
		};
		/**
		 * Gets cookie
		 */
		CookieUtils.getCookie = function (name) {
			return getCookie(name);
		};
		/**
		 * Deletes cookie
		 */
		CookieUtils.deleteCookie = function (name) {
			return deleteCookie(name);
		};
		return CookieUtils;
	})();
	SeenecoFramework.CookieUtils = CookieUtils;
	var MessageUtils = (function () {
		function MessageUtils() {}
		/**
		 * Shows message
		 */
		MessageUtils.show = function (text, alertClass, duration, containerID, addCloseBtn) {
			return showMessage(text, alertClass, duration, containerID, addCloseBtn);
		};
		return MessageUtils;
	})();
	SeenecoFramework.MessageUtils = MessageUtils;
	var ArrayUtils = (function () {
		function ArrayUtils() {}
		/**
		 * Builds a hierarchical structure from an array of items with child-to-parent relations
		 */
		ArrayUtils.unflatten = function (array, parent, tree, idField, parentIDField, childrenField, iteratee) {
			return unflatten(array, parent, tree, idField, parentIDField, childrenField, iteratee);
		};
		return ArrayUtils;
	})();
	SeenecoFramework.ArrayUtils = ArrayUtils;
	var I18NUtils = (function () {
		function I18NUtils() {}
		/**
		 * Loads i18n messages from file and sets values to the map
		 */
		I18NUtils.loadMessages = function (path, name, scope, callback) {
			return loadMessages(path, name, scope, callback);
		};
		return I18NUtils;
	})();
	SeenecoFramework.I18NUtils = I18NUtils;
	var ExportUtils = (function () {
		function ExportUtils() {}
		/**
		 * Export dataset to Excel file
		 */
		ExportUtils.datasetToExcel = function (dataset, fileName, columnsMetadata, headerTableRowsMap, rowsMetadata) {
			return exportDatasetToExcel(dataset, fileName, columnsMetadata, headerTableRowsMap, rowsMetadata);
		};
		return ExportUtils;
	})();
	SeenecoFramework.ExportUtils = ExportUtils;
	var FormContextUtils = (function () {
		function FormContextUtils() {}
		/**
		 * Get parameter value by name from URL
		 */
		FormContextUtils.getParameterValue = function (parameterName) {
			return getFormContextParameter(parameterName);
		};
		return FormContextUtils;
	})();
	SeenecoFramework.FormContextUtils = FormContextUtils;
	var DateUtils = (function () {
		function DateUtils() {}
		DateUtils.getRussianDateString = function (dateStr, separator, position) {
			return dateStr;
		};
		DateUtils.convertMonthLocale = function (monthStr, from, to) {
			return monthStr;
		};
		DateUtils.monthNamesByLocales = {
			ru: 'Январь_Февраль_Март_Апрель_Май_Июнь_Июль_Август_Сентябрь_Октябрь_Ноябрь_Декабрь'.split('_'),
			en: 'January_February_March_April_May_June_July_August_September_October_November_December'.split('_'),
		};
		return DateUtils;
	})();
	SeenecoFramework.DateUtils = DateUtils;
})(SeenecoFramework || (SeenecoFramework = {}));
//Export for **Node.js**
if (typeof exports !== 'undefined' && typeof module !== 'undefined' && module.exports) {
	module.exports = SeenecoFramework;
}
