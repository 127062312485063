/* eslint-disable @typescript-eslint/prefer-regexp-exec */
/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable no-redeclare */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/***
 * Library contains common Alatyr Framework functions and classes
 * @author skadnikov
 */

var pageContext = {};
var webServiceQueryParams = {};
var webserviceRestartCounter = 0;

/***
 * Classifier of domain model element belonging to ontology
 */
function OntologyClassifier() {
	this.PACKAGE = '';
	this.CLASSIFIER = '';
}

/***
 * Typed reference to other domain object
 * It references domain object by its GUID and {@link OntologyClassifier}
 *
 */
function TypedReference() {
	this.PACKAGE = 'CORE';
	this.CLASSIFIER = 'TYPED_REFERENCE';
	this.ModuleLevelContainerGUID = '';
	this.GUID = '';
	this.LocalID = -1;
	this.ReferencedGUID = '';
	this.ReferencedClassifier = null;
	this.initFromSource = function (source) {
		this.ReferencedGUID = source.GUID;
		this.LocalID = source.LocalID;
		var refCls = new OntologyClassifier();
		refCls.PACKAGE = source.PACKAGE;
		refCls.CLASSIFIER = source.CLASSIFIER;
		this.ReferencedClassifier = refCls;
	};
}

function SecurityRightCheckHandle(guid) {
	this.guid = guid;
	this.getGUID = function () {
		return this.guid;
	};
}

function getSecutiryHeader(login, password) {
	return '<UserLogin>' + login + '</UserLogin><UserPassword>' + password + '</UserPassword>';
}

function getStaticSecutiryHeader() {
	//console.time('getStaticSecutiryHeader');
	var login = pageContext[USER_LOGIN_VARIABLE];
	if (!login) {
		var elem = document.getElementById('_' + USER_LOGIN_VARIABLE);
		login = elem ? elem.getAttribute('value') : '';
		pageContext[USER_LOGIN_VARIABLE] = login;
	}
	var password = pageContext[USER_PASSWORD_VARIABLE];
	if (!password) {
		var elem = document.getElementById('_' + USER_PASSWORD_VARIABLE);
		password = elem ? elem.getAttribute('value') : '';
		pageContext[USER_PASSWORD_VARIABLE] = password;
	}
	//console.timeEnd('getStaticSecutiryHeader');
	return login && password ? getSecutiryHeader(login, password) : null;
}

/**Creates new SOAP XML message for interaction with Alatyr Framework web service
 *@param operationName - System name of the target service operation
 *@param parameterNames - Array of String containing operation parameter names
 *@param parameterValues - Array of Object containing operation parameter values
 *@return SOAP XML message string
 */
function buildSOAPMessage(operationName, parameterNames, parameterValues, securityHeader) {
	var soapMessage = "<?xml version='1.0' encoding='utf-8'?>";
	soapMessage += "<soap:Envelope xmlns:xsi='http://www.w3.org/2001/XMLSchema-instance'  ";
	soapMessage += "xmlns:xsd='http://www.w3.org/2001/XMLSchema' ";
	soapMessage += "xmlns:soap='http://schemas.xmlsoap.org/soap/envelope/'>";
	soapMessage += '<soap:Header>';
	soapMessage += "<SecurityHeader xmlns='http://tempuri.org/'>";
	soapMessage += securityHeader || getStaticSecutiryHeader() || '<TicketUID>' + getCurrentTicketUID() + '</TicketUID>';
	soapMessage += '</SecurityHeader>';
	soapMessage += '</soap:Header>';
	soapMessage += '<soap:Body>';
	soapMessage += '<' + operationName + " xmlns='http://analytics.alatyrsoftlab.com/'>";
	for (var i = 0; i < parameterNames.length; i++) {
		soapMessage += '<' + parameterNames[i] + " xmlns=''>";
		soapMessage += escapeHtmlEntities(stripScriptTagsFromHTML(stripCommentsFromHTML(parameterValues[i])));
		soapMessage += '</' + parameterNames[i] + '>';
	}
	soapMessage += '</' + operationName + '>';
	soapMessage += '</soap:Body>';
	soapMessage += '</soap:Envelope>';
	return soapMessage;
}

function filterServerErrorStack(string) {
	var arr = (string || '').split('\n');
	var filteredArr = [];
	var blacklist = ['class="sun', 'class="com.sun', 'class="org.apache', 'class="org.eclipse'];

	arr.forEach(function (str, i) {
		var resStr = str;

		blacklist.forEach(function (item, j) {
			if (str.indexOf(item) >= 0) {
				resStr = '...';
				return false;
			}
		});

		if (resStr !== filteredArr[filteredArr.length - 1]) {
			filteredArr.push(resStr);
		}
	});

	return filteredArr.join('\n');
}

function parseXML(source) {
	var parser;

	if (typeof DOMParser !== 'undefined') {
		parser = function (str) {
			return new window.DOMParser().parseFromString(str, 'text/xml');
		};
	} else if (typeof ActiveXObject !== 'undefined') {
		parser = function (str) {
			var xmlDoc = new ActiveXObject('Microsoft.XMLDOM');
			xmlDoc.async = false;
			xmlDoc.loadXML(str);

			return xmlDoc;
		};
	} else {
		throw new Error('No XML parser found');
	}

	return parser(source);
}

function callWebService(
	serviceName,
	soapMessage,
	resultHasComplexType,
	serviceCallbackHandler,
	serviceApplicationName,
	autoRestartOnError,
) {
	var rootPath = getWebServicesRootPath();
	rootPath = rootPath + '/' + (serviceApplicationName || 'atr-framework-services') + '/webservices/';

	if (!rootPath) {
		var msg = 'Undefined variable that stores root address of web services';
		console.log(msg);
		alert(msg);
	} else {
		var queryString = buildURIQueryFromMap(getWebServiceQueryParams());
		var currentLanguage = getCurrentLanguage();
		var that = this,
			args = arguments;
		var url = rootPath + serviceName + (queryString ? '?' + queryString : '');

		// prettier-ignore
		fetch(url, {
			method: 'post',
			headers: {
				'Access-Control-Allow-Origin': '*',
				'Content-Type': 'text/xml;charset=utf-8',
				'X-ATR-User-Defined-Locale': currentLanguage
			},
			credentials: 'include',
			body: soapMessage
		})
			.then(function (response) {
				return response.ok ? response.text() : Promise.reject(response);
			})
			.then(function (data) {
				if (data === null) {
					if (autoRestartOnError && webserviceRestartCounter < 5) {
						webserviceRestartCounter++;
						console.warn(
							'resending request for webservice ' + serviceName + ', attempt #' + webserviceRestartCounter + '...',
						);
						callWebService.apply(that, args);
					} else {
						// prettier-ignore
						return window.globalEmitErrorMessage({
							title: 'Ответ от сервиса ' + serviceName + ' равен null'
						});
					}
				}

				var xml = parseXML(data);
				var authResultNode = xml.getElementsByTagName('AuthResult').item(0);
				var resultNode = xml.getElementsByTagName('return').item(0);
				// Read node containing returned result in JSON string
				var authResult = authResultNode && authResultNode.textContent;
				var resultString = resultNode && resultNode.textContent;

				if (authResult == 'error') {
					console.error('Auth error');
					window.location.href = '/auth/sign-in';
				} else {
					if (resultHasComplexType) {
						var jsonResult = {};
						try {
							if (resultString !== null && resultString !== '') {
								jsonResult = JSON.parse(resultString);
							}
						} catch (e) {
							// prettier-ignore
							window.globalEmitErrorMessage({
								title: 'Ошибка при разборе JSON-ответа от сервиса ' + serviceName,
								message: 'JSON: ' + resultString,
								error: e
							});
						}
						try {
							serviceCallbackHandler(jsonResult);
						} catch (e) {
							// prettier-ignore
							window.globalEmitErrorMessage({
								title: 'Ошибка при обработке ответа от сервиса ' + serviceName,
								error: e
							});
						}
					} else {
						serviceCallbackHandler(resultString);
					}
				}
				webserviceRestartCounter = 0;
			})
			.catch(function (error) {
				if (error instanceof Response) {
					error.text().then(function (responseText) {
						// prettier-ignore
						var xhr = {
							status: error.status,
							statusText: error.statusText,
							responseText: responseText
						};

						var status = xhr.status;
						var responseText = xhr.responseText;
						var statusText = xhr.statusText;

						console.error('response: ', error);
						console.error('xhr: ', xhr);

						if (responseText) {
							if (status === 0) {
								console.error("Request didn't reach the server - was not sent");
							} else {
								if (autoRestartOnError && webserviceRestartCounter < 5) {
									webserviceRestartCounter++;
									console.warn(
										'resending request for webservice ' +
											serviceName +
											', attempt #' +
											webserviceRestartCounter +
											'...',
									);
									callWebService.apply(that, args);
								} else {
									var xml = parseXML(responseText);
									var errorNode = xml.getElementsByTagName('faultstring').item(0);
									var errorText = errorNode && errorNode.textContent;

									// prettier-ignore
									window.globalEmitErrorMessage({
										title: 'Ошибка при обращении к сервису ' + serviceName,
										message: errorText + '; Статус сервера: ' + status + ' / ' + statusText,
										xhr: xhr,
										soapMessage: soapMessage
									});
								}
							}
						} else {
							// prettier-ignore
							window.globalEmitErrorMessage({
								title: 'Ошибка при обращении к сервису ' + serviceName,
								message: 'Неизвестная ошибка; Статус сервера: ' + status + ' / ' + statusText,
								xhr: xhr,
								soapMessage: soapMessage
							});
						}
					});
				} else {
					// prettier-ignore
					window.globalEmitErrorMessage({
						isNetworkTrouble: true,
						title: error.message,
						message: error.stack
					});
				}
			});
	}
}

function isLocalhost() {
	return location.hostname === 'localhost' || location.hostname === '127.0.0.1';
}

function logError(e, extra) {
	console.error(e.stack);
	if (!isLocalhost() && window.Raven) {
		Raven.captureException(e, { extra: extra });
	}
}

function getWebServiceQueryParams() {
	return webServiceQueryParams;
}

function buildURIQueryFromMap(obj) {
	var str = Object.keys(obj)
		.map(function (key) {
			return key + '=' + obj[key];
		})
		.join('&');

	return str;
}

/***
 * Returns web services root path
 */
function getWebServicesRootPath() {
	//console.time('getWebServicesRootPath');
	var rootPath = pageContext[WEB_SERVICES_ROOT_VARIABLE];
	if (!rootPath) {
		var elem = document.getElementById('_' + WEB_SERVICES_ROOT_VARIABLE);
		rootPath = elem ? elem.getAttribute('value') : '';
		pageContext[WEB_SERVICES_ROOT_VARIABLE] = rootPath;
	}
	//console.timeEnd('getWebServicesRootPath');
	return rootPath;
}

/***
 * Returns current user login
 */
function getCurrentTicketUID() {
	var ticketUID = pageContext[TICKET_UID_VARIABLE];
	if (!ticketUID) {
		var elem = document.getElementById('_' + TICKET_UID_VARIABLE);
		ticketUID = elem ? elem.getAttribute('value') : getURLParameterByName(TICKET_UID_VARIABLE);
		pageContext[TICKET_UID_VARIABLE] = ticketUID;
	}

	return ticketUID;
}

function getCurrentLanguage() {
	return 'ru';
}

/**
 * Retuns cookie with delivered name, if exists, or undefined
 */
function getCookie(name) {
	var matches = document.cookie.match(
		new RegExp('(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'),
	);
	return matches ? decodeURIComponent(matches[1]) : undefined;
}

/**
 * Sets cookie with delivered name, value and options
 * @param options - optional object with additional settings for cookie (expires, path, domain, secure)
 */
function setCookie(name, value, options) {
	options = options || {};

	var expires = options.expires;

	if (typeof expires == 'number' && expires) {
		var d = new Date();
		d.setTime(d.getTime() + expires * 1000);
		expires = options.expires = d;
	}
	if (expires && expires.toUTCString) {
		options.expires = expires.toUTCString();
	}

	value = encodeURIComponent(value);

	var updatedCookie = name + '=' + value;

	for (var propName in options) {
		updatedCookie += '; ' + propName;
		var propValue = options[propName];
		if (propValue !== true) {
			updatedCookie += '=' + propValue;
		}
	}

	document.cookie = updatedCookie;
}

/**
 * Deletes cookie with delivered name
 */
function deleteCookie(name) {
	setCookie(name, '', { expires: -1 });
}

if (!window.location.origin) {
	window.location.origin =
		window.location.protocol +
		'//' +
		window.location.hostname +
		(window.location.port ? ':' + window.location.port : '');
}

function stringToFloat(string) {
	if (typeof string !== 'string') string = '';
	var float = parseFloat(string.replace(/\s+/g, '').replace(',', '.').replace('–' /*&ndash;*/, '-' /*&minus;*/)) || 0;
	return float;
}

function escapeHtmlEntities(text) {
	if (typeof text !== 'string') return text;
	var entityTable = {
		60: 'lt',
		62: 'gt',
		34: 'quot',
		38: 'amp',
		39: 'apos',
	};
	return text.replace(/[\u00A0-\u2666<>\&'"]/g, function (c) {
		return '&' + (entityTable[c.charCodeAt(0)] || '#' + c.charCodeAt(0)) + ';';
	});
}

function getURLParameterByName(name) {
	name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
	var regex = new RegExp('[\\?&]' + name + '=([^&#]*)'),
		results = regex.exec(location.search);
	return results == null ? '' : escapeHtmlEntities(decodeURIComponent(results[1].replace(/\+/g, ' ')));
}

/**
 * Format a number and return a string based on input settings
 * @param {Number} number The input number to format
 * @param {Number} decimals The amount of decimals
 * @param {String} decPoint The decimal point, defaults to the one given in the lang options
 * @param {String} thousandsSep The thousands separator, defaults to the one given in the lang options
 */
function numberFormat(number, decimals, decPoint, thousandsSep) {
	decimals = decimals || 0;
	decPoint = decPoint || ',';
	thousandsSep = thousandsSep || ' ';
	var mathAbs = Math.abs;
	var pInt = parseInt;
	var //lang = defaultOptions.lang,
		// http://kevin.vanzonneveld.net/techblog/article/javascript_equivalent_for_phps_number_format/
		n = +number || 0,
		c =
			decimals === -1
				? (n.toString().split('.')[1] || '').length // preserve decimals
				: isNaN((decimals = mathAbs(decimals)))
				? 2
				: decimals,
		d = decPoint /* === undefined ? lang.decimalPoint : decPoint*/,
		t = thousandsSep /* === undefined ? lang.thousandsSep : thousandsSep*/,
		s = n < 0 ? '-' : '',
		i = String(pInt((n = mathAbs(n).toFixed(c)))),
		j = i.length > 3 ? i.length % 3 : 0;

	var r =
		s +
		(j ? i.substr(0, j) + t : '') +
		i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t) +
		(c
			? d +
			  mathAbs(n - i)
					.toFixed(c)
					.slice(2)
			: '');
	// replace trailing zeroes in decimal part
	r = trimTrailingZeros(r, decPoint);
	return r;
}

function trimTrailingZeros(str, decPoint) {
	if (str.indexOf(decPoint) >= 0) {
		var length = 0;
		for (var i = str.length - 1; i >= 0; i--) {
			var char = str.charAt(i);
			if (char !== '0' || char == decPoint) {
				break;
			} else {
				length++;
			}
		}
		if (length > 0) {
			str = str.slice(0, -length);
			if (str.charAt(str.length - 1) == decPoint) {
				str = str.slice(0, -1);
			}
		}
	}
	return str;
}

function stripScriptTagsFromHTML(html) {
	if (typeof html !== 'string') return html;
	if (html.indexOf('<script') >= 0) {
		var div = document.createElement('div');
		div.innerHTML = html;
		var scripts = div.getElementsByTagName('script');
		var i = scripts.length;
		while (i--) {
			scripts[i].parentNode.removeChild(scripts[i]);
		}
		return div.innerHTML;
	} else {
		return html;
	}
}

function stripCommentsFromHTML(html) {
	if (typeof html !== 'string') return html;
	if (html.indexOf('<!--') >= 0 && html.indexOf('-->') >= 0) {
		return html.replace(/<!--(.*?)-->/g, '');
	} else {
		return html;
	}
}

// prettier-ignore
var coreLibContainer = {
	numberFormat: numberFormat,
	stringToFloat: stringToFloat,
	setCookie: setCookie,
	getCookie: getCookie,
	deleteCookie: deleteCookie,
	getCurrentLanguage: getCurrentLanguage,
	pageContext: pageContext,
	getWebServicesRootPath: getWebServicesRootPath,
	SecurityRightCheckHandle: SecurityRightCheckHandle,
	buildSOAPMessage: buildSOAPMessage,
	callWebService: callWebService,
	getURLParameterByName: getURLParameterByName,
	filterServerErrorStack: filterServerErrorStack,
	logError: logError
};

for (var k in coreLibContainer) {
	window[k] = coreLibContainer[k];
}

export { pageContext, filterServerErrorStack, logError };
